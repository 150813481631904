@import '@fluentui/react/dist/sass/References';


.mutatielog {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .mutatielog-title {

  }
  
}

.mutatielog-table {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}