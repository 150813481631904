@import '@fluentui/react/dist/sass/References';
@import 'src/styles/constants';

.mutatielogitem {
  
  &-content {
    padding: 8px;

    .mutatielogitem-row {
      display: flex;
      flex-direction: row;
      padding: 8px 12px;
      border-bottom: 1px solid $ms-color-neutralLight;

      .mutatielogitem-row-label {
        font-weight: $ms-font-weight-semibold;
        width: 175px;
      }
      
        .mutatielogitem-row-value {
            flex: 1 1 auto;
        }
    }
    
    
    table {
      border-collapse: collapse;
    }
    
    table th, table td {
      border: 0;
      text-align: left;
      padding-left: 8px;
    }
    
    table th  {
      padding: 8px 0 4px 8px;
      border-bottom: 1px solid $ms-color-gray30;
    }
    
    td.verschil {
      background-color: $OHK-BLUE-EXTRA-LIGHT;
      font-weight: $ms-font-weight-semibold;
    }
    
  }

  
  
}

