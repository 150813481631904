.endpoint-protocol {
    
    .ms-ChoiceFieldGroup-flexContainer {
        display: flex;
        flex-direction: row;
        gap: 16px;
        .ms-ChoiceField {
            margin-top: 0;
        }
    }
}

.endpoint-protocol-edit {
    border-bottom: 1px solid rgb(239, 239, 239);
    width: 100%;
    
    div {
        display: flex;
        flex-direction: row;

        .ms-ChoiceFieldGroup-flexContainer {
            padding: 0 8px;
        }
        
        .ms-Label {
            margin: 0px 8px 0px 0px;
            padding: 5px 0px 5px 12px;
            overflow-wrap: break-word;
            line-height: 22px;
            height: 32px;
            width: 175px;
        }
    }
}
