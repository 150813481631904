@import '@fluentui/react/dist/sass/References';

.start {
  padding: 16px 8px 8px 16px;

  div {
    padding: 8px 0;
  }

  .page-title {
    font-size: $ms-font-size-18;
    font-weight: $ms-font-weight-semibold;
    padding: 0 0 16px 0;
    margin: 0;
  }

  .title  {
    padding: 0 0 12px 0;
  }
}