@import 'src/styles/constants';

.importeren {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    span.ms-Pivot-icon {
      color: $GREEN-1;
    }

    &-tabs {
      padding: 0;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    &-navigation {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $TOOLBAR-BORDER_COLOR;
      button.ms-Button {
        margin: 8px;
      }
      
    }
  }

  .title {
    font-weight: 600;
    padding-bottom: 12px;
  }

}