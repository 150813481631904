.resultaat {
  padding: 16px;
  
  &-ok {
    padding-bottom: 16px;
  }

  &-aannemers-title.title {
    padding-top: 32px;
    padding-bottom: 0;
  }
  
}