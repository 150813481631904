// Oonderhoudsketen standaardkleuren
$OHK-ORANGE-STRONG: #ff5800;
$OHK-ORANGE-LIGHT: #ffb400;
$OHK-BLUE-DARK: #174d95;
$OHK-BLUE-LIGHT: #37bdfd;
$OHK-BLUE-EXTRA-LIGHT: #def2fc;

$WHITE-1: #ffffff;

$GRAY-1: #efefef;
$GRAY-2: #e1dfdd00;
$GRAY-3: #00000029;
$GRAY-4: #333333;
$GRAY-5: #6e6f77;

$BLUE-1: #216297;
$BLUE-2: #2592c6;

$GREEN-1: #5cbf7f;

$NAVBAR-BG: $GRAY-1;
$NAVBAR-HOVER: $WHITE-1;

$TOOLBAR-HEIGHT: 48px;
$TOOLBAR-ICON-HOVER: $GRAY-3;
$TOOLBAR-BG: $OHK-BLUE-DARK;
$TOOLBAR-ICON-COLOR: $WHITE-1;
$TOOLBAR-BORDER_COLOR: #efefef;

$ACTIONBAR_HEIGHT: 40px;

$TEXT-COLOR-LIGHT: $WHITE-1;
$TABLE-HEADER-TEXT-COLOR: $GRAY-5;

$CARD-PAGE-MARGIN: 14px;


