@import 'src/styles/constants';

.page {
  position: relative;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.content {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  //width: calc(100vw - 201px);
  height: calc(100vh - 48px);
}

.greenIcon {
  color: $GREEN-1;
}

.icon {
  color: $GRAY-4;
}
