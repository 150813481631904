.berichtbezorging-statusfilter-choice .ms-ChoiceField {
    padding-right: 4px;
    margin-top: 0;
}

.berichtbezorging-statusfilter-choice div:first-child {
    display: flex;
}

.berichtbezorging-statusfilter-choice label {
    padding-right: 8px;
}

.berichtbezorging-statusfilter-choice .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
}