@import "src/styles/constants";
@import '@fluentui/react/dist/sass/References';

.controleren {
  padding: 16px;
  
  .title {
    padding: 0 0 16px 0;
  }
  
  &-ok {
    padding-bottom: 32px;
    
    &-title {
      padding-bottom: 8px;
    }

    .controleren-ok-verder.title {
      padding-top: 16px;
    }
  }
  
  &-problemen {
    &-algemeen {
      &-bericht {
        color: $ms-color-alert;
      }
    }
    
    &-toelichting {
      padding-top: 12px;
    }
  }
  .ms-MessageBar {
    max-width: 700px;
    padding: 8px 0;
  }
  
}