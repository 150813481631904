@import 'styles/constants';
@import '~@fluentui/react/dist/sass/References';

.callback-toolbar {

  height: 48px;
  background: $TOOLBAR-BG;
  opacity: 1;

  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  &-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: nowrap;
  }

  &-item {
    border-radius: 0;
    height: 48px;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

}
