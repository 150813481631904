.uploaden {
  padding: 16px 8px 8px 16px;
  display: flex;
  flex-direction: column;
  > div {
    flex: 0 0 auto;
  }
  
  &-file {
    padding: 16px 0 32px 16px;
    
    &-info {
      padding-top: 8px;
    }
  }
  
  &-preview {
    flex: 1 1 auto;
    padding: 0 16px;
    
    &-next {
      padding-top: 16px;
    }
  }

  
  
  &-error {
    padding: 0 16px;
    max-width: 700px;
    
    .ms-MessageBar-innerText, .ms-MessageBar-text {
      font-size: 1.1em;
      line-height: 1.2em;
    }
  }
  
}