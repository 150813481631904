@import '@fluentui/react/dist/sass/References';
@import 'src/styles/constants';

.endpoint-information {
  &-content {
    padding: 8px;

    .endpoint-information-row {
      display: flex;
      flex-direction: row;
      padding: 8px 12px;
      border-bottom: 1px solid $ms-color-neutralLight;

      .endpoint-information-row-label {
        font-weight: $ms-font-weight-semibold;
        width: 175px;
      }

      .endpoint-information-row-value {
        flex: 1 1 auto;
      }
    }
    
  }
}