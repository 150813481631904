@import 'src/styles/constants';

.ohkTableWrapper {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.spinnerWrapper {
  margin-top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.column span {
  font-size: '12px';
  font-weight: 'normal';
  color: '#FFA500';
}
