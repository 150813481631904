.berichtbezorgingen {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 auto;

  &-content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  &-header-actions {
    padding-left: 8px;
    padding-top: 8px;
  }

}


