body > html > #root > div {
  height: 100%;
  margin: 0;
  padding: 0;
}

html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
  padding: 0;
}
