@import 'src/styles/constants';

.inputCard {
  display: flex;
  flex-direction: column;
  //flex-grow: 1;
  border: 1px solid $GRAY-1;
  min-width: 340px;
  box-shadow: $GRAY-1 0px 0px 9px -1px;
  padding: 4px 14px 32px;
  box-sizing: border-box;
}

.inputCardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
