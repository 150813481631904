

.flex-container {
    display: flex;
    overflow: hidden;
}

.flex-container > * {
    flex: 0 0 auto;
    overflow: hidden;
}

.flex-column {
    flex-direction: column;
}

.flex-grow {
    flex: 1 1 auto;
}

.full-height {
    height: 100%;
}

.scrollbar-auto {
    overflow: auto;
}

.flex-gap-8 {
    gap: 8px;
}

.flex-gap-16 {
    gap: 16px;
}