@import '~@fluentui/react/dist/sass/References';

.callback {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.container {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  padding: 30px 10px 10px 10px;
  overflow: auto;
}

ol {
  padding-left: 10px;
}

ol li {
  text-align: justify;
  margin: 0;
}

.popupContainer {
  margin: 0 20px;
  border: 1px solid $ms-color-sharedGray10;
  border-radius: 8px;
  padding: 20px;
  @include ms-depth-8;
}

