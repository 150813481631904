@import '@fluentui/react/dist/sass/References';

.businessrules {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 auto;

  .page-title {
    font-size: $ms-font-size-20;
    font-weight: $ms-font-weight-bold;
    margin: 8px 0;
    padding: 0 8px;
  }
  
}
