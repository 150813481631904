@import '@fluentui/react/dist/sass/References';

.afhandelen {

  &-spinner {
    padding-top: 32px;

    .ms-Spinner--large {
      width: 40px;
      height: 40px;
    }
  }

}

.afhandelen-title {
  font-size: $ms-font-size-20;
  font-weight: $ms-font-weight-semibold;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 8px;
}

.afhandelen-status-toelichting {
  padding-left: 8px;
}
