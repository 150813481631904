@import '@fluentui/react/dist/sass/References';

.page-title {
  font-size: $ms-font-size-20;
  font-weight: $ms-font-weight-semibold;
  margin: 8px 0;
  padding: 0 8px;
}

.padding-8 {
  padding: 8px;
}

.padding-16 {
  padding: 16px;
}

.max-width-800 {
  max-width: 800px;
}

.max-width-1200 {
  max-width: 1200px;
}
