@import '@fluentui/react/dist/sass/References';

.berichtbezorgingen-table {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.status-field {
    color: $ms-color-sharedGray10;
}
.status-field.status-field-1, .status-field.status-field-4 {
    color: $ms-color-alert;
}

.status-field.status-field-3, .status-field.status-field-5  {
    color: $ms-color-success;
}

.status-field.status-field-2 {
    color: $ms-color-sharedGray10;
}